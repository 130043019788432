import React, { useState, useEffect, useContext, createContext, useRef } from "react";
import { Auth, Hub } from 'aws-amplify';
import MemberService from "../Services/MemberService";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>
    {auth.user && children}
  </authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const ref = useRef({
    customOAuthState: null
  });

  const signin = async (email, password, recaptchaToken) => {
    if (recaptchaToken) {
      return await Auth.signIn({
        username: email.trim().toLowerCase(),
        password: password,
        validationData: { 'captcha': recaptchaToken }
      });
    }
    else {
      return await Auth.signIn(email.trim().toLowerCase(), password);
    }
  };

  const signup = async (email, password, name, recaptchaToken) => {
    return await Auth.signUp({
      username: email.trim().toLowerCase(),
      password: password,
      attributes: {
        name: name
      },
      clientMetadata: {
        'captcha': recaptchaToken
      }
    })
  };

  const signout = async () => await Auth.signOut()

  const confirmPasswordReset = async (email, code, password) => await Auth.forgotPasswordSubmit(email.trim().toLowerCase(), code, password);

  const confirmSignUp = async (username, code) => await Auth.confirmSignUp(username.trim().toLowerCase(), code);

  const registerEventHandlers = (newEventHandlers) => {
    if (!ref.current.eventHandlers) {
      ref.current.eventHandlers = newEventHandlers;
    }
  }

  const forgotPassword = async (username, recaptchaToken) => await Auth.forgotPassword(username.trim().toLowerCase(), { 'captcha': recaptchaToken });

  const federatedSignIn = async (provider, returnUrl) => await Auth.federatedSignIn({
    provider: provider,
    customState: returnUrl
  });

  const resendSignUp = async (username) => await Auth.resendSignUp(username.trim().toLowerCase());

  const setAuthenticatedUser = async (currentUserInfo) => {
    setUser({
      authenticated: true,
      userId: currentUserInfo.attributes.sub,
      isAdmin: currentUserInfo.attributes['custom:isAdmin'] === 'true',
      ...currentUserInfo.attributes
    });
    if (ref.current.customOAuthState && ref.current.eventHandlers && ref.current.eventHandlers.onCustomOAuthState) {
      ref.current.eventHandlers.onCustomOAuthState(ref.current.customOAuthState);
      ref.current.customOAuthState = null;
    }
    MemberService.tryClaimGuest();
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(setAuthenticatedUser)
      .catch(() => {
        setUser({
          authenticated: false
        });
      })
    const authListener = (data) => {
      if (data.payload.event === "signIn") {
        Auth.currentAuthenticatedUser()
          .then(setAuthenticatedUser)
      }
      else if (data.payload.event === "signOut") {
        setUser({
          authenticated: false
        });
      }
      else if (data.payload.event === "customOAuthState") {
        ref.current.customOAuthState = data.payload.data;
      }
    }
    Hub.listen('auth', authListener);

    // Cleanup subscription on unmount
    return () => Hub.remove('auth', authListener);
  }, []);
  const updateUserAttributes = async (updatedAttributes) => {
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(currentUser, updatedAttributes);
  }

  const changePassword = async (oldpassword, newpassword) => {
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(currentUser, oldpassword, newpassword);
  }

  const updateUserDetails = (updates) => {
    setUser({
      ...user,
      ...updates
    })
  }

  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    confirmPasswordReset,
    confirmSignUp,
    forgotPassword,
    federatedSignIn,
    resendSignUp,
    registerEventHandlers,
    updateUserAttributes,
    changePassword,
    updateUserDetails
  };
}