import axios from 'axios';
import _ from 'lodash';
import authenticationService from './AuthenticationService';
import { v4 as uuid } from 'uuid';

const client = axios.create({
  baseURL: process.env.REACT_APP_SELL_API_URL
});

let transientInventory = [];

const self = {
  create: async (sellerId, name, email, category) => {
    let response = await client.post('/inventory', {
      sellerId: sellerId,
      name: name,
      email: email,
      category
    }, await authenticationService.getRequestOptions(null, true));

    return response.data;
  },
  createTransient: (userId, user, category) => {
    const inventory = {
      id: uuid(),
      primarySubCategory: { level1: "NONE", level2: "NONE" },
      secondarySubCategory: { level1: "NONE", level2: "NONE" },
      category: category,
      sellerId: userId,
      contactDetails: {
        name: user.name,
        email: user.email
      },
      adDetails: {
        currencyCode: 'AUD',
        priceType: 'AMOUNT',
        features: {
          roadRegistered: false,
          rollCage: false,
          logBook: false
        },
        wasPricing: {
          enabled: false,
          price: 0,
          prices: []
        },
        specification: {}
      },
      itemLocation: {
        country: 'Australia',
        countryCode: 'AU'
      },
      state: 'DRAFT',
      salesState: 'NONE',
      tier: 'BETTER',
      purchases: {
        pending: [
          {
            key: 'BETTER'
          }
        ],
        past: []
      },
      images: [],
      statsAndTipsNotificationFrequencyDays: 7,
      isTransient: true
    };
    transientInventory.push(inventory);
    return inventory;
  },
  get: async (id) => {
    let listing = transientInventory.find(x => x.id === id);

    if (!listing) {
      const response = await client.get(`/inventory/${id}`, await authenticationService.getRequestOptions(null, true));

      listing = response.data;
    }

    // These are nulls are needed to detect when changes have occurred for validation
    listing.adDetails = listing.adDetails || {};
    listing.adDetails.make = listing.adDetails.make || null;
    listing.adDetails.model = listing.adDetails.model || null;
    listing.adDetails.year = listing.adDetails.year || null;
    listing.adDetails.title = listing.adDetails.title || null;
    listing.adDetails.description = listing.adDetails.description || null;
    listing.adDetails.price = listing.adDetails.price || null;
    listing.adDetails.currencyCode = listing.adDetails.currencyCode || null;
    listing.saleDetails = listing.saleDetails || {
      saleType: 'OFFERED',
      durationDays: 7
    };
    listing.saleDetails.reservePrice = listing.saleDetails.reservePrice || null;
    listing.sellerDetails = listing.sellerDetails || {
      sellerType: 'PRIVATE'
    };
    listing.sellerDetails.licenseNumber = listing.sellerDetails.licenseNumber || null;

    return listing;
  },
  listBySeller: async (sellerId) => {
    const response = await client.get(`/seller/${sellerId}/inventory/`, await authenticationService.getRequestOptions());

    return response.data;
  },
  search: async (filters) => {
    const response = await client.post(`/inventory/search/`, filters, await authenticationService.getRequestOptions());

    return response.data;
  },
  getAdAnalyticsForPastDays: async (adId, daysAgo) => {
    const response = await client.get(`/inventory/${adId}/days/${daysAgo}`, await authenticationService.getRequestOptions());

    return response.data;
  },
  getAdHistory: async (adId) => {
    const response = await client.get(`/inventory/${adId}/history`, await authenticationService.getRequestOptions());

    return response.data;
  },
  save: async (listingId, listing) => {
    const updateRequest = {
      ...listing,
      // Remove nulls that we need for validation purposes because dynamodb hates them
      adDetails: _.omitBy(listing.adDetails, _.isNil)
    }

    const response = await client.put(`/inventory/${listingId}`, updateRequest, await authenticationService.getRequestOptions(null, true));

    transientInventory = transientInventory.filter(x => x.id !== listingId);

    return response.data;
  },
  adminSave: async (listingId, listing) => {
    const response = await client.put(`/inventory/${listingId}/admin`, listing, await authenticationService.getRequestOptions());

    return response.data;
  },
  publish: async (listingId, listing) => {
    const response = await client.put(`/inventory/${listingId}/publish`, listing, await authenticationService.getRequestOptions());

    return response.data;
  },
  purchaseUpgrades: async (listingId, listing) => {
    const response = await client.put(`/inventory/${listingId}/purchase-upgrades`, listing, await authenticationService.getRequestOptions());

    return response.data;
  },
  updateStatus: async (listingId, newStatus) => {
    const response = await client.put(`/inventory/${listingId}/status/${newStatus}`, {}, await authenticationService.getRequestOptions());

    return response.data;
  },
  updateNotification: async (listingId, newFrequency) => {
    const response = await client.put(`/inventory/${listingId}/notification/${newFrequency}`, {}, await authenticationService.getRequestOptions());

    return response.data;
  },
  updatePromoCode: async (listingId, promocode, state, addedOn) => {
    const request = {
      code: promocode,
      state: state,
      addedOn: addedOn
    }

    const response = await client.put(`/inventory/${listingId}/updatepromocode`, request, await authenticationService.getRequestOptions());

    return response.data;
  },
  uploadImage: async (listingId, imageFile, order) => {
    const uploadUrlResponse = await client.post('/images/signed-url', {
      "contentType": imageFile.type,
      "inventoryId": listingId,
      "order": order
    }, await authenticationService.getRequestOptions(null, true));

    var options = {
      headers: {
        'Content-Type': imageFile.type
      }
    };

    await client.put(uploadUrlResponse.data.uploadUrl, imageFile, options);

    return uploadUrlResponse.data;
  },
  getImageThumbnail: async (imageKey) => {
    const response = await client.get(`/image/${imageKey}/thumbnail`, await authenticationService.getRequestOptions(null, true));

    return response.data;
  },
  getAdStatsSubscription: async (token) => {
    const response = await client.get(`/ad-stats-subscription?token=${token}`);

    return response.data;
  },
  setAdStatsSubscription: async (request) => {
    const response = await client.post(`/ad-stats-subscription`, request);

    return response.data;
  }
};

export default self;